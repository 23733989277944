.ExternalAuthOptions {
  width: 300px;
  margin-top: 40px;
  .disclaimer {
    margin-top: 5px;
    color: var(--black40);
    font-style: italic;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .ExternalAuthOptions {
    width: 100%;
  }
}
