.Authentication {
  .connect-prompt {
    margin-top: 20px;
  }
  .info-row {
    display: flex;
    flex-direction: row;
    .info-item {
      margin-left: 10px;
    }
  }
  .heatpump-image {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 900px) {
  .Authentication {
    .intro-details {
      display: none;
    }
    .heatpump-image {
      display: none;
    }
  }
}
