.Input {
  font-family: 'Yellix-Regular';
  padding: 12px;
  font-size: 16px;
  border-radius: 8px;
  height: 30px;
  border: 1px solid var(--black40);
  color: var(--dark-brown);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border: 1px solid var(--black75);
  }
}
