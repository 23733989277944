.Text {
  font-family: 'Yellix-Medium';
  font-size: 17px;
  color: var(--black);
  text-align: left;
  line-height: 25px;

  &.small {
    font-size: 14px;
    line-height: 20px;
  }

  &.large {
    font-size: 20px;
    line-height: 30px;
  }

  &.bold {
    font-family: 'Yellix-SemiBold';
  }
}
