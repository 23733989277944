.CollaborationPartner {
  display: flex;
  margin: auto;
  .collaboration-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .collaboration-text {
    background-color: var(--white);
  }
}

@media screen and (max-width: 900px) {
  .CollaborationPartner {
    width: 100%;
    .collaboration-content {
      flex-direction: column;
      justify-content: start;
      align-items: start;
    }

    .collaboration-text {
      text-align: start;
    }
  }
}

@media screen and (max-width: 600px) {
  .CollaborationPartner {
    .collaboration-text {
      text-align: center;
    }
    .collaboration-content {
      justify-content: center;
      align-items: center;
      margin: auto;
    }
  }
}
