.ExternalAuthCallback {
  .connect-prompt {
    margin-top: 20px;
  }
  .connected-text {
    background-color: var(--primary-yellow);
  }
  .info-row {
    display: flex;
    flex-direction: row;
    .info-item {
      margin-left: 10px;
    }
  }
  .heatpump-image {
    width: 100%;
    height: 100%;
  }

  .devices {
    margin-top: 40px;
    .device-ids-container {
      text-align: start;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
}

@media (max-width: 900px) {
  .ExternalAuthCallback {
    .heatpump-image {
      display: none;
    }
  }
}
