@font-face {
  font-family: 'Yellix-Regular';
  src: url('./assets/fonts/Yellix-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Yellix-Light';
  src: url('./assets/fonts/Yellix-Light.otf') format(truetype);
}

@font-face {
  font-family: 'Yellix-Medium';
  src: url('./assets/fonts/Yellix-Medium.otf') format('truetype');
}

@font-face {
  font-family: 'Yellix-SemiBold';
  src: url('./assets/fonts/Yellix-SemiBold.otf') format('opentype');
}

body {
  font-family: 'Yellix-Regular', sans-serif;
  margin: auto 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  --white: #ffffff;
  --black: #000000;
  --black75: #404040;
  --black60: #666666;
  --black40: #999999;
  --black15: #d9d9d9;
  --gray100: #f9fafa;
  --gray200: #f4f5f6;
  --gray300: #eceeef;
  --gray400: #ced2d4;
  --gray500: #a1abaf;
  --primary-green: #4b4d00;
  --primary-yellow: #e5ea00;
  --beige: #fffbf4;
  --rich-beige: #f0e8d9;
  --camel: #d9be8f;
  --dark-brown: #252015;
  --light-brown: #c5bba4;
  --success: #7ace0e;
  --warning: #e5ea00;
  --danger: #de3b00;

  .text-center {
    text-align: center;
  }

  .display-flex {
    display: flex;
  }

  .font-yellix-light {
    font-family: 'Yellix-Light';
  }

  .font-yellix-medium {
    font-family: 'Yellix-Medium';
  }

  .font-yellix-semibold {
    font-family: 'Yellix-SemiBold';
  }
}
