.Content {
  .main-content {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
  }

  .headline-container {
    flex: 3;
    margin-right: 130px;
    display: flex;
    flex-direction: column;
    min-height: 50vh;
  }
  .headline-content {
    padding-right: 200px;
  }

  .description {
    margin-top: 20px;
  }

  .right-container {
    flex: 2;
  }
  .bottom-container {
    justify-content: center;
    margin: auto;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 1200px) {
  .Content {
    .headline-content {
      padding-right: 0;
    }
  }
}

@media (max-width: 900px) {
  .Content {
    .main-content {
      flex-direction: column;
    }
    .headline-container {
      min-height: auto;
      margin-bottom: 15px;
      text-align: center;
      margin-right: auto;

      .headline-text {
        text-align: center;
      }
    }
    .bottom-container {
      background-color: var(--white);
    }
  }
}
