.Layout {
  text-align: center;
  background-color: var(--white);
  min-height: 100vh;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 105px;
  }
  .sign-out-container {
    padding: 32px;
  }
  .content {
    padding: 32px;
    overflow-y: scroll;
  }
  .logo {
    padding: 32px;
    height: 25px;
    pointer-events: none;
  }
  .footer {
    margin-top: auto;
    min-height: 50px;
    background-color: var(--black);
    color: var(--white);
    width: 100%;
    text-align: left;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  .footer-text {
    padding: 10px;
    color: var(--white);
  }
}

@media (max-width: 600px) {
  .Layout {
    .header {
      flex-direction: column;
      height: auto;
    }
    .logo {
      padding: 16px;
    }
    .sign-out-container {
      padding: 16px;
    }
    .content {
      padding: 16px;
    }
  }
}
