.OtpInput {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 300px;
  /* Remove up/down arrows for Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .digit-input {
    font-family: 'Yellix-Regular';
    font-size: 16px;
    height: 30px;
    width: 25px;
    text-align: center;
    border-radius: 8px;
    padding: 5px;
    border: 1px solid var(--black40);
    color: var(--dark-brown);
    margin: 2px;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
