.Button {
  font-family: 'Yellix-Medium', sans-serif;
  background-color: var(--black);
  color: var(--white);
  border-radius: 5px;
  min-height: 40px;
  min-width: 200px;
  border: 0px;
  width: 100%;
  cursor: pointer;
  &:disabled {
    background-color: var(--black40);
    &:hover {
      cursor: not-allowed;
    }
  }
}
